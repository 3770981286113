import Head from "next/head";
import {
  DEFAULT,
  DEFAULTBUSINESS,
  DEFAULTGATE,
  DEFAULTGATEMALUKU,
  DEFAULTGATEPESAWARAN,
  DEFAULTGATETRENGGALEK,
} from "./metaData";
import { SocialMeta } from "./SocialMeta";
import {
  isMalukuURL,
  isPesawaranURL,
  isTrenggalekURL,
  useIsBusiness,
  useIsGate,
  useIsGateProvince,
  useIsSubdomain,
  useIsTenant,
} from "lib/host";
import { useRouter } from "next/router";

export const DefaultMeta = ({ withDefault }) => {
  const isB2B = useIsTenant() || useIsSubdomain();
  const isBusiness = !isB2B;
  const isGate = useIsGate();
  const isTenant = useIsSubdomain();
  const isGateMaluku = useIsGateProvince();
  // only for maluku tenant
  // TODO: for generate PWA Manifest only
  // const isGateTrenggalek = false;
  // const isGatePesawaran = true;

  let manifest = "/manifest.webmanifest";
  let defaultMeta = null;

  if (isBusiness) {
    manifest = "/business.webmanifest";
    defaultMeta = DEFAULTBUSINESS;
    // } else if (isGateMaluku) {
    //   manifest = "/maluku.webmanifest";
    //   defaultMeta = DEFAULTGATEMALUKU;
    // } else if (isGateTrenggalek) {
    //   manifest = "/trenggalek.webmanifest";
    //   defaultMeta = DEFAULTGATETRENGGALEK;
    // } else if (isGatePesawaran) {
    //   manifest = "/pesawaran.webmanifest";
    //   defaultMeta = DEFAULTGATEPESAWARAN;
  } else if (isGate) {
    manifest = "/gate.webmanifest";
    defaultMeta = DEFAULTGATE;
  } else if (isTenant) {
    if (isMalukuURL(window.location.hostname)) {
      defaultMeta = DEFAULTGATEMALUKU;
      manifest = "/maluku.webmanifest";
    } else if (isTrenggalekURL(window.location.hostname)) {
      defaultMeta = DEFAULTGATETRENGGALEK;
      manifest = "/trenggalek.webmanifest";
    } else if (isPesawaranURL(window.location.hostname)) {
      defaultMeta = DEFAULTGATEPESAWARAN;
      manifest = "/pesawaran.webmanifest";
    } else {
      defaultMeta = { ...DEFAULT, url: window.location.href };
    }
  } else {
    defaultMeta = DEFAULT;
  }

  const pathName = useRouter().asPath;
  const isContainsKuliah = pathName.includes("kuliah");

  return (
    <>
      {withDefault && <SocialMeta />}
      <Head>
        <meta name="referrer" content="strict-origin-when-cross-origin" />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
        />
        {/* disallow indexing */}
        {isContainsKuliah && <meta name="robots" content="noindex" />}
        <meta name="application-name" content={defaultMeta.appTitle} />
        <meta name="theme-color" content={defaultMeta.themeColor} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />

        <link rel="manifest" href={manifest} />
        <link rel="shortcut icon" href="/favicon.ico" />
      </Head>
    </>
  );
};
